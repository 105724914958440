import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Container from '../components/Layout/container';
import { MainSection100 } from '../components/Layout/sections';
import NewLayout from '../components/new_layout';
import { CarouselTouch } from '../components/v2024/Carousel/carousel-touch';
import { SocialMediaTecalis } from '../components/v2024/social-media';
import Opinions from "../components/v2023/Opinions/opinions"

const ThankYouContact = ({ data, pageContext }) => {
	const page = data.page;
	const lang = pageContext.langKey;

	return (
		<NewLayout pageContext={pageContext} noHeader thinFooter>
			<div className="thankyou">
				<MainSection100 sectionType="color" sectionColor="gray">
					<Container section="100">
						<GatsbyImage
							alt={page.logo_tecalis.alternativeText}
							image={getImage(page.logo_tecalis.localFile)}
						/>
						<div className="container__text">
							<GatsbyImage alt={page.hero.image.alternativeText} image={getImage(page.hero.image.localFile)} />
							<div className="container__text__text">
								<h1 className='merriweather'>{page.hero.title}</h1>
							</div>
						</div>
					</Container>
				</MainSection100>
				<MainSection100>
					<Container containerThin>
						<div className="grid-md">
							{page.logos.map((logo) => (
								<GatsbyImage alt={logo.alternativeText} image={getImage(logo.localFile)} />
							))}
						</div>
					</Container>
				</MainSection100>
				<Opinions lang={lang} opinions={page.opinions_opinion} />
				<MainSection100>
					<Container section="100">
						<SocialMediaTecalis secondary title={page.titulo_redes}/>
					</Container>
				</MainSection100>
			</div>
		</NewLayout>
	);
};

export default ThankYouContact;

export const agradecimientosContactosQuery = graphql`
	query ($id: String!, $langKey: String!) {
		page: strapiAgradecimientosContactos(locale: { eq: $langKey }, id: { eq: $id }) {
			logo_tecalis {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 147, placeholder: BLURRED, formats: [WEBP])
					}
				}
			}
			hero {
				title
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 164, placeholder: BLURRED, formats: [WEBP])
						}
					}
				}
			}
			logos {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 120, placeholder: BLURRED, formats: [WEBP])
					}
				}
			}
			opinions_opinion {
				opinion {
					name
					job_position
					message
					company_logo_2 {
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(width: 280, placeholder: BLURRED, formats: [WEBP])
							}
						}
					}
				}
			}
			titulo_redes
		}
	}
`;
